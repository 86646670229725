<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4 mt-2">
          <div class="d-flex align-items-center">
            <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${currentUserPersonalInfo.photo})`
                }"
              ></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                >{{ getFullName }}</a
              >
              <div class="text-muted">
                {{
                  currentUserPersonalInfo.professional
                    ? "Professionnel"
                    : "Particulier"
                }}
              </div>
              <div class="mt-2">
                <a
                  class="btn btn-sm btn-primary font-weight-bold py-2 px-3 px-xxl-5 my-1"
                  >Mes fichiers</a
                >
              </div>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Contact-->
          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Email:</span>
              <a class="text-muted text-hover-primary">{{
                currentUserPersonalInfo.email
              }}</a>
            </div>
            <div
              class="d-flex align-items-center justify-content-between"
              v-if="currentUserPersonalInfo.professional"
            >
              <span class="font-weight-bold mr-2">Company Name:</span>
              <span class="text-muted">{{
                currentUserPersonalInfo.denomiation
              }}</span>
            </div>
          </div>
          <!--end::Contact-->
          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <router-link
                :to="{ name: 'profile' }"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="navi-link py-4"
                  :href="href"
                  @click="navigate"
                  :class="{ active: isActive && isExactActive }"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Design/Layers.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Profile Overview</span>
                </a>
              </router-link>
            </div>
            <div class="navi-item mb-2">
              <router-link
                :to="{ name: 'personalInfo' }"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  class="navi-link py-4"
                  :href="href"
                  @click="navigate"
                  :class="{ active: isActive }"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/General/User.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">
                    Personal Information
                  </span>
                </a>
              </router-link>
            </div>
            <div class="navi-item mb-2">
              <router-link
                :to="{ name: 'account' }"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  class="navi-link py-4"
                  :href="href"
                  @click="navigate"
                  :class="{ active: isActive }"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg"
                    >Account Information</span
                  >
                </a>
              </router-link>
            </div>
            <div class="navi-item mb-2">
              <router-link
                :to="{ name: 'changePassword' }"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  class="navi-link py-4"
                  :href="href"
                  @click="navigate"
                  :class="{ active: isActive }"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Communication/Shield-user.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Change Passwort</span>
                  <span class="navi-label">
                    <span
                      class="label label-light-danger label-rounded font-weight-bold"
                      >5</span
                    >
                  </span>
                </a>
              </router-link>
            </div>
            <div class="navi-item mb-2">
              <router-link
                :to="{ name: 'settings' }"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  class="navi-link py-4"
                  :href="href"
                  @click="navigate"
                  :class="{ active: isActive }"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-opened.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Email settings</span>
                </a>
              </router-link>
            </div>
            <div class="navi-item mb-2">
              <router-link
                :to="{ name: 'payment' }"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  class="navi-link py-4"
                  :href="href"
                  @click="navigate"
                  :class="{
                    active: isActive
                  }"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Shopping/Credit-card.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Saved Credit Cards</span>
                </a>
              </router-link>
            </div>
            <div class="navi-item mb-2">
              <router-link
                :to="{ name: 'invoices' }"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  class="navi-link py-4"
                  :href="href"
                  @click="navigate"
                  :class="{ active: isActive }"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Files/File.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Invoice</span>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs">
        <b-tab>
          <router-view></router-view>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "custom-page",
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    }
  }
};
</script>
